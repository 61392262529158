var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0010285" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              Boolean(_vm.param.limLicenseRequestId) &&
                              _vm.deleteEditable &&
                              !_vm.disabled,
                            expression:
                              "editable && Boolean(param.limLicenseRequestId) && deleteEditable && !disabled",
                          },
                        ],
                        attrs: { label: "LBLREMOVE", icon: "delete_forever" },
                        on: { btnClicked: _vm.deleteData },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable && !disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.licenseRequest,
                          mappingType: _vm.mappingType,
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              Boolean(_vm.param.limLicenseRequestId) &&
                              !_vm.disabled,
                            expression:
                              "editable && Boolean(param.limLicenseRequestId) && !disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.receiptUrl,
                          isSubmit: _vm.isRequest,
                          param: _vm.licenseRequest,
                          mappingType: "PUT",
                          label: "인허가요청",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.requestData,
                          btnCallback: _vm.requestCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "LBL0010276",
                        name: "licenseRequestName",
                      },
                      model: {
                        value: _vm.licenseRequest.licenseRequestName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.licenseRequest,
                            "licenseRequestName",
                            $$v
                          )
                        },
                        expression: "licenseRequest.licenseRequestName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.licenseRequest.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "plantCd", $$v)
                        },
                        expression: "licenseRequest.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-field", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        data: _vm.licenseRequest,
                        deptValue: "requestDeptCd",
                        type: "dept_user",
                        label: "LBL0010287",
                        name: "requestUserId",
                      },
                      model: {
                        value: _vm.licenseRequest.requestUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "requestUserId", $$v)
                        },
                        expression: "licenseRequest.requestUserId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-field", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        data: _vm.licenseRequest,
                        deptValue: "reviewDeptCd",
                        type: "dept_user",
                        label: "LBL0010288",
                        name: "reviewUserId",
                      },
                      model: {
                        value: _vm.licenseRequest.reviewUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "reviewUserId", $$v)
                        },
                        expression: "licenseRequest.reviewUserId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "LBL0010289",
                        name: "completeRequestDate",
                      },
                      model: {
                        value: _vm.licenseRequest.completeRequestDate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.licenseRequest,
                            "completeRequestDate",
                            $$v
                          )
                        },
                        expression: "licenseRequest.completeRequestDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        rows: 2,
                        name: "requestContent",
                        label: "LBL0010290",
                      },
                      model: {
                        value: _vm.licenseRequest.requestContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "requestContent", $$v)
                        },
                        expression: "licenseRequest.requestContent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("c-textarea", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.licenseRequest.requestStepCd == "RSC0999999",
                          expression:
                            "licenseRequest.requestStepCd == 'RSC0999999'",
                        },
                      ],
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "LBL0010291",
                        name: "dismissReason",
                      },
                      model: {
                        value: _vm.licenseRequest.dismissReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseRequest, "dismissReason", $$v)
                        },
                        expression: "licenseRequest.dismissReason",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0010292",
            columns: _vm.grid.columns,
            data: _vm.licenseRequest.reviews,
            gridHeight: _vm.grid.height,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            isExcelDown: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "limLicenseReviewId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "limLicenseKindId"
                    ? [
                        _c("c-license-kind", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.disabled,
                            data: props.row,
                            relatedLawsName: "relatedLawsName",
                            label: "",
                            name: "limLicenseKindId",
                          },
                          on: { dataChange: (val) => _vm.dataChange(props) },
                          model: {
                            value: props.row.limLicenseKindId,
                            callback: function ($$v) {
                              _vm.$set(props.row, "limLicenseKindId", $$v)
                            },
                            expression: "props.row.limLicenseKindId",
                          },
                        }),
                      ]
                    : col.name === "asLicenseSeq"
                    ? [
                        props.row.renewalFlag == "Y"
                          ? _c("c-license", {
                              attrs: {
                                required: true,
                                editable: _vm.editable && !_vm.disabled,
                                data: props.row,
                                searchLimLicenseKindId:
                                  props.row.limLicenseKindId,
                                relatedLawsName: "relatedLawsName",
                                limLicenseKindId: "limLicenseKindId",
                                limLicenseKindName: "asLicenseSeqName",
                                label: "",
                                name: "asLicenseSeq",
                              },
                              on: {
                                dataChange: (val) => _vm.dataChange(props),
                              },
                              model: {
                                value: props.row.asLicenseSeq,
                                callback: function ($$v) {
                                  _vm.$set(props.row, "asLicenseSeq", $$v)
                                },
                                expression: "props.row.asLicenseSeq",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addReview },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.deleteReview },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }